import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({title, description, keywords}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} /> 
        </Helmet>
    )
}

Meta.defaultProps = {
    title: 'Welcome to Banaly Tcom',
    description: 'Best Ever Tcom Platform.',
    keywords: 'ecom, temple, tcom, banaly, software, erp, sales, online sales ecommerce offering'
}
export default Meta
