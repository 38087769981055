import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { register, registerConfirm } from '../actions/userActions'


const RegisterScreen = ({ location, history }) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState(null);
    const [tokenrcvd, setTokenrcvd] = useState('')

    const dispatch = useDispatch()

    const userRegister = useSelector(state => state.userRegister)
    const { loading, error, success } = userRegister

    const userRegisterConfirm = useSelector(state => state.userRegisterConfirm)
    const { loading: loadingRegisterConfirm, error: errorRegisterConfirm, userInfo: confirmedUserInfo } = userRegisterConfirm

    const redirect = location.search ? location.search.split('=')[1] : '/'

    useEffect(() => {
        if (confirmedUserInfo) {
            history.push(redirect)
        }
    }, [history,  confirmedUserInfo, redirect])

    const submitHandler = (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(register(name, email, password))
        }
    }

    const registerConfirmHandler = (e) => {
        e.preventDefault()
        dispatch(registerConfirm(email, tokenrcvd))
    }

    return <>
        <h1>Sign Up</h1>
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {errorRegisterConfirm && <Message variant='danger'>{errorRegisterConfirm}</Message>}
        {loading && <Loader />}
        {loadingRegisterConfirm && <Loader />}
        {success
            ? (
                <FormContainer>
                    <h2>Please check your email for verification code.</h2>
                    <Form onSubmit={registerConfirmHandler}>
                        <Form.Group controlId='name'>
                            <Form.Label>Verification Code</Form.Label>
                            <Form.Control
                                type='name'
                                placeholder='Enter Verification Code'
                                value={tokenrcvd}
                                onChange={(e) => setTokenrcvd(e.target.value)}>
                            </Form.Control>
                        </Form.Group>
                        <Button type='submit' variant='primary'>Verify</Button>
                    </Form>
                </FormContainer>
            )
            : (
                <FormContainer>
                    <Form onSubmit={submitHandler}>
                        <Form.Group controlId='name'>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type='name'
                                placeholder='Enter Name'
                                value={name}
                                required
                                onChange={(e) => setName(e.target.value)}>

                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='email'>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                type='email'
                                placeholder='Enter Email'
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}>

                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='password'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type='password'
                                placeholder='Enter Password'
                                value={password}
                                required
                                onChange={(e) => setPassword(e.target.value)}>

                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='confirmPassword'>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type='password'
                                placeholder='Confirm Password'
                                value={confirmPassword}
                                required
                                onChange={(e) => setConfirmPassword(e.target.value)}>

                            </Form.Control>
                        </Form.Group>
                        <Button type='submit' variant='primary'>Register</Button>
                    </Form>
                    <Row className='py-3'>
                        <Col>
                            Have an Account?{' '}
                            <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                                Login
                            </Link>
                        </Col>
                    </Row>
                </FormContainer>

            )}
    </>
}

export default RegisterScreen
