import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { getOrderDetails, getRazorPayOrderId, deliverOrder } from '../actions/orderActions'
import { ORDER_DETAILS_SUCCESS, ORDER_DELIVER_RESET } from '../constants/orderConstants';

const OrderScreen = ({ match, history }) => {
    const orderId = match.params.id

    // const [sdkReady, setSdkReady] = useState(false)

    const dispatch = useDispatch()

    // const makePayment = useSelector(state => state.makePayment)
    // const { loading: loadingMakePayment, success: successMakePayment, paymentresponse } = makePayment

    const orderDetails = useSelector(state => state.orderDetails)
    const { order, loading, error } = orderDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const orderRpCreate = useSelector(state => state.orderRpCreate)
    const { loading: loadingRpPay } = orderRpCreate

    const orderPay = useSelector(state => state.orderPay)
    const { loading: loadingPay } = orderPay

    const orderDeliver = useSelector(state => state.orderDeliver)
    const { success: successDeliver } = orderDeliver

    const addRazorPayScript = async () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `https://checkout.razorpay.com/v1/checkout.js`
        script.async = true
        script.onload = () => {
            console.log("script loaded")
            // setSdkReady(true)
        }
        document.body.appendChild(script)
    }

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        }
        if (!order || order._id !== orderId || successDeliver) {
            dispatch({ type: ORDER_DELIVER_RESET })
            dispatch(getOrderDetails(orderId))
        } else {
            dispatch({
                type: ORDER_DETAILS_SUCCESS,
                payload: order
            })
        }
        addRazorPayScript()
        // eslint-disable-next-line
    }, [dispatch, orderId, successDeliver])

    const makePaymentHandler = () => {
        dispatch(
            getRazorPayOrderId(order._id, order.totalPrice * 100)
        )
    }

    const deliverHandler = () => {
        dispatch(
            deliverOrder(order)
        )
    }

    const formatDate = (inputdate,format) => {
        switch (format) {
            case "DD-MM-YYYY":
                return inputdate.substring(8,10)+'-'+inputdate.substring(5,7)+'-'+inputdate.substring(0,4)
            default:
                return inputdate
        }
    }

    return loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> : <>
        <h1>Order {order._id}</h1>
        <Row>
            <Col md={8}>
                <ListGroup variant='flush'>
                    <ListGroup.Item>
                        <h2>Shipping</h2>
                        <p><strong>Name: </strong> {order.user.name}</p>
                        <p><strong>Email: </strong><a href={`mailto:${order.user.email}`}>{order.user.email}</a></p>
                        <p>
                            <strong>Address:</strong>
                            {order.shippingAddress.address},
                                {order.shippingAddress.city}{' '},
                                {order.shippingAddress.district}{' '},
                                {order.shippingAddress.state}{' '},
                                {order.shippingAddress.country}{' '},
                                {order.shippingAddress.postalCode}
                        </p>
                        {order.isDelivered ? (
                            <Message variant='success'>Delivered on {order.deliveredAt}</Message>
                        ) : (
                                <Message variant='danger'>Not Delivered</Message>
                            )}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h2>Payment Method</h2>
                        <p>
                            <strong>Method: </strong>
                            {order.paymentMethod}</p>
                        {order.isPaid ? (
                            <Message variant='success'>Paid on {order.paidAt}</Message>
                        ) : (
                                <Message variant='danger'>Not Paid</Message>
                            )}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h2>Order Items</h2>
                        {order.orderItems.lenght === 0 ? <Message>Your cart is empty</Message> : (
                            <ListGroup variant='flush'>
                                {order.orderItems.map((item, index) => (
                                    <ListGroup.Item key={index}>
                                        <Row>
                                            <Col md={1}>
                                                <Image src={item.image} alt={item.name} fluid rounded />
                                            </Col>
                                            <Col>
                                                <Link to={`/product/${item.product}`}>
                                                    {item.name}
                                                </Link>
                                            </Col>
                                            <Col md={4}>
                                                {item.qty} x &#8377;{item.price} = &#8377;{item.qty * item.price}
                                            </Col>
                                        </Row>
                                        <ListGroup>{
                                            item.benefList.map((benef) => (
                                                <ListGroup.Item key={benef.benefName}>

                                                    <div className="info">{benef.benefName}</div>
                                                    <div className="info">{benef.benefStar}</div>
                                                    <div className="info">{formatDate(benef.benefDate, "DD-MM-YYYY")}</div>

                                                </ListGroup.Item>
                                            ))
                                        }
                                        </ListGroup>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                    </ListGroup.Item>
                </ListGroup>
            </Col>
            <Col md={4}>
                <Card>
                    <ListGroup variant='flush'>
                        <ListGroup.Item>
                            <h2>Order Summary</h2>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col>Items</Col>
                                <Col>&#8377;{order.itemsPrice}</Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col>Shipping</Col>
                                <Col>&#8377;{order.shippingPrice}</Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col>Tax</Col>
                                <Col>&#8377;{order.taxPrice}</Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col>Total</Col>
                                <Col>&#8377;{order.totalPrice}</Col>
                            </Row>
                        </ListGroup.Item>
                        {/* <ListGroup.Item>
                            <Button type='button' className='btn-block'
                                disabled={order.isPaid} onClick={makePaymentHandler}>
                                Make Payment
                                        </Button>
                        </ListGroup.Item> */}
                        {!order.isPaid && (
                            <ListGroup.Item>
                                {
                                    loadingRpPay || loadingPay
                                        ? <Loader></Loader>
                                        : (
                                            <Button type='button' className='btn-block'
                                                disabled={order.isPaid} onClick={makePaymentHandler}>
                                                Make Payment
                                            </Button>
                                        )
                                }
                            </ListGroup.Item>
                        )}
                        {userInfo && userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                            <ListGroup.Item>
                                <Button type='button' className='btn btn-block' onClick={deliverHandler}>Mark as Delivered</Button>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Card>
            </Col>
        </Row>
    </>
}

export default OrderScreen
