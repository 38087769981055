import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Card, Button, Form } from 'react-bootstrap'
import Rating from '../components/Rating'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import { listProductDetails, createProductReview } from '../actions/productActions'
import { PRODUCT_ADD_BENEF, PRODUCT_ADD_BENEF_SUCCESS_RESET, PRODUCT_CLEAR_BENEFS, PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'

const ProductScreen = ({ history, match }) => {
    var someDate = new Date();
    const numberOfDaysToAdd = 2;
    someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    var dd = someDate.getDate();
    var mm = someDate.getMonth() + 1;
    const yyyy = someDate.getFullYear();
    if (mm.length < 2)
        mm = '0' + mm;
    if (dd.length < 2)
        dd = '0' + dd;
    const startDate = yyyy + '-' + mm + '-' + dd;

    const [benefName, setBenefName] = useState('');
    const [benefStar, setBenefStar] = useState('');
    const [benefDate, setBenefDate] = useState('');
    const [qty, setQty] = useState(1);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const stars = [
        'Aswathi', 'Bharani', 'Karthika', 'Rohini', 'Makayiram', 'Thiruvathira', 'Punartham',
        'Pooyam', 'Aayillyam', 'Makam', 'Pooram', 'Uthram', 'Atham', 'Chithira',
        'Chothi', 'Vishakam', 'Anizham', 'Thriketta', 'Moolam', 'Pooraadam', 'Uthraadam',
        'Thiruvonam', 'Avittam', 'Chathayam', 'Poororuttathi', 'Uthrattathi', 'Revathi'
    ]
    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails);
    const { loading, error, product } = productDetails

    const benef = useSelector(state => state.benef);
    const { benefList, success: successProductAddBenef } = benef

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin

    const productReviewCreate = useSelector(state => state.productReviewCreate);
    const { success: successProductReview, error: errorProductReview } = productReviewCreate

    useEffect(() => {
        if (benefList.length > 0 && benefList[0].productId !== match.params.id) {
            dispatch({
                type: PRODUCT_CLEAR_BENEFS
            })
        }
        if (successProductReview) {
            alert('Review Submitted!')
            setRating(0)
            setComment('',)
            dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
        }
        if (successProductAddBenef) {
            setQty(benefList.length)
            setBenefName('')
            setBenefStar('')
            setBenefDate('')
            dispatch({ type: PRODUCT_ADD_BENEF_SUCCESS_RESET })
        }
        dispatch(listProductDetails(match.params.id))
    }, [dispatch, match, successProductReview, successProductAddBenef, benefList])

    const addToCartHandler = () => {
        history.push(`/cart/${match.params.id}?qty=${qty}`)
    }

    const formatDate = (inputdate, format) => {
        switch (format) {
            case "DD-MM-YYYY":
                return inputdate.substring(8, 10) + '-' + inputdate.substring(5, 7) + '-' + inputdate.substring(0, 4)
            default:
                return inputdate
        }
    }

    const addPersonDetails = (e) => {
        e.preventDefault()
        dispatch({
            type: PRODUCT_ADD_BENEF,
            payload: {
                productId: product._id,
                benefName,
                benefStar,
                benefDate
            }
        })
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createProductReview(match.params.id, {
            rating,
            comment
        }))
    }
    return (
        <>
            <Link className='btn btn-light my-3' to='/'>
                GO BACK
           </Link>
            {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> : (
                <>
                    <Meta title={product.name} />
                    <Row>
                        <Col md={6}>
                            <Image src={product.image} alt={product.name} fluid></Image>
                        </Col>
                        <Col md={3}>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <h3>{product.name}</h3>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Rating
                                        value={product.rating}
                                        text={`${product.numReviews} reviews`}
                                    />
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    Price: &#8377;{product.price}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    Description: {product.description}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <ListGroup variant='flush'>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                Price:
                                    </Col>
                                            <Col>
                                                <strong>&#8377;{product.price}</strong>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                Status:
                                    </Col>
                                            <Col>
                                                {product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <Form onSubmit={addPersonDetails}>
                                        <ListGroup.Item>
                                            <Form.Control type='text' name='name' value={benefName}
                                                placeholder='Input Name' required
                                                onChange={(e) => setBenefName(e.target.value)}
                                            >
                                            </Form.Control>
                                        </ListGroup.Item>
                                        {product.benefStarReq && (
                                            <ListGroup.Item>
                                                <Form.Control as='select' placeholder='Choose Star' required
                                                    value={benefStar}
                                                    onChange={(e) => setBenefStar(e.target.value)}
                                                >
                                                    <option key="label" value=''>--Choose Star--</option>
                                                    {
                                                        stars.map((star) => (
                                                            <option key={star} value={star}>
                                                                {star}
                                                            </option>
                                                        ))
                                                    }
                                                </Form.Control>
                                            </ListGroup.Item>
                                        )
                                        }
                                        <ListGroup.Item>
                                            <Form.Control type='date' name='date' required
                                                value={benefDate}
                                                placeholder='Choose Date' min={startDate}
                                                onChange={(e) => setBenefDate(e.target.value)}
                                            >
                                            </Form.Control>
                                        </ListGroup.Item>
                                        <ListGroup.Item>

                                            <Button
                                                className='btn-block circlebtn' type='submit' disabled={product.countInStock === 0}>
                                                Add Person
                                </Button>
                                        </ListGroup.Item>

                                    </Form>
                                    <ListGroup>{
                                        benefList.map((benef) => (
                                            <ListGroup.Item key={benef.benefName}>

                                                <div className="info">{benef.benefName}</div>
                                                <div className="info">{benef.benefStar}</div>
                                                <div className="info">{formatDate(benef.benefDate, "DD-MM-YYYY")}</div>

                                            </ListGroup.Item>
                                        ))
                                    }
                                    </ListGroup>
                                    {product.countInStock > 0 && (
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    Qty</Col>
                                                <Col>
                                                    {/* <Form.Control as='select' value={qty} onChange={(e) =>
                                                        setQty(e.target.value)
                                                    }>
                                                        {
                                                            [...Array(product.countInStock).keys()].map((x) => (
                                                                <option key={x + 1} value={x + 1}>
                                                                    {x + 1}
                                                                </option>
                                                            ))
                                                        }
                                                    </Form.Control> */}
                                                    {
                                                        benefList.length
                                                    }
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    )}
                                    <Button
                                        onClick={addToCartHandler}
                                        className='btn-block' type='button' disabled={product.countInStock === 0 || benefList.length === 0}>
                                        Add To Cart
                                </Button>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <h2>Reviews</h2>
                            {product.reviews.length === 0 && <Message>No Reviews</Message>}
                            <ListGroup variant='flush'>
                                {product.reviews.map(review => (
                                    <ListGroup.Item key={review._id}>
                                        <strong>{review.name}</strong>
                                        <Rating value={review.rating} />
                                        <p>{review.createdAt.substring(0, 10)}</p>
                                        <p>{review.comment}</p>
                                    </ListGroup.Item>
                                ))}
                                <ListGroup.Item>
                                    <h2>Write a Review</h2>
                                    {errorProductReview && <Message variant='danger'>{errorProductReview}</Message>}
                                    {userInfo ? (
                                        <Form onSubmit={submitHandler}>
                                            <Form.Group controlId='rating'>
                                                <Form.Label>Rating</Form.Label>
                                                <Form.Control
                                                    as='select'
                                                    value={rating}
                                                    onChange={(e) => setRating(e.target.value)}>
                                                    <option value=''>Select...</option>
                                                    <option value='1'>1 - Poor</option>
                                                    <option value='2'>2 - Fair</option>
                                                    <option value='3'>3 - Good</option>
                                                    <option value='4'>4 - Very Good</option>
                                                    <option value='5'>5 - Excellent</option>
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId='comment'>
                                                <Form.Label>Comment</Form.Label>
                                                <Form.Control
                                                    as='textarea'
                                                    row='3'
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)}>
                                                </Form.Control>
                                            </Form.Group>
                                            <Button type='submit' variant='primary'>
                                                Submit
                                            </Button>
                                        </Form>
                                    )
                                        : <Message>
                                            Please <Link to='/login'>Login</Link> to write a review
                                    {' '}
                                        </Message>}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default ProductScreen
